import { MAILCHIMP_URL, user_types } from './constants.js';

const createAccountSigninButton = document.getElementById('create-account-signin-button');
const createAccountSignupButton = document.getElementById('create-account-signup-button');
const contactButton = document.getElementById('contact-button');

const examination_section = document.getElementById('examination-cards');

const modalExamination = document.getElementById('modal-examination');
const modalExaminationButton = document.getElementById('modal-examination-button');
const modalExaminationButtonText = document.getElementById('modal-examination-button-text');
const modalExaminationIcon = document.getElementById('modal-examination-icon');
const modalExaminationTitle = document.getElementById('modal-examination-title');
const modalExaminationSubtitle = document.getElementById('modal-examination-subtitle');
const modalExaminationSummary = document.getElementById('modal-examination-summary');

const userButton1 = document.getElementById('user-card-button-1');
const userButton2 = document.getElementById('user-card-button-2');
const userButton3 = document.getElementById('user-card-button-3');
const modalUserImg = document.getElementById('modal-user-img');
const modalButtonTitle = document.getElementById('modal-user-button');
const modalUserTitle = document.getElementById('modal-user-title');
const modalUserSubtitle = document.getElementById('modal-user-subtitle');
const modalUserSummary = document.getElementById('modal-user-summary');
const modalUserSubtitle2 = document.getElementById('modal-user-subtitle2');
const modalUserSummary2 = document.getElementById('modal-user-summary2');
const modalBottomButtonText = document.getElementById('modal-bottom-button-text');
const modalBottomButton = document.getElementById('modal-bottom-button');

createAccountSigninButton.href = process.env.EXAMINATIONS_ROOT;
createAccountSignupButton.href = `${process.env.EXAMINATIONS_ROOT}/sign-up`;
contactButton.onclick = function () {
    const left = (screen.width - 570) / 2;
    const top = (screen.height - window.outerHeight) / 2;
    const params = `menubar=no,toolbar=no,status=no,width=570,height=${window.outerHeight},top=${top},left=${left}`;
    window.open(MAILCHIMP_URL, 'NewWindow', params);
};

function fetchExaminations() {
    userButton1.onclick = function () {
        showUserSummary(1);
    };
    userButton2.onclick = function () {
        showUserSummary(2);
    };
    userButton3.onclick = function () {
        showUserSummary(3);
    };

    fetch(`${process.env.API_ENDPOINT}/examination_types`, {
        method: 'GET',
        // eslint-disable-next-line no-undef
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        credentials: 'same-origin'
    })
        .then((response) => response.json())
        .then((examinations) => {
            examination_section.textContent = '';
            examinations.examination_types.forEach(function (examination, i) {
                if (i < 9) displayPost(examination);
            });
            if (window.location.hash) {
                const hash = window.location.hash.slice(1);
                let exId;
                if (
                    examinations.examination_types.some((ex) => {
                        if (ex.name.split(' ').join('-').toLocaleLowerCase() == hash) {
                            exId = ex.id;
                            return true;
                        }
                    })
                ) {
                    document.getElementById(exId).click();
                }
            }
        });
}

function displayPost(examination) {
    const card = document.createElement('a');
    card.classList.add('col-9', 'col-md-6', 'col-lg-4', 'examination-card');
    card.setAttribute('data-bs-toggle', 'modal');
    card.setAttribute('data-bs-target', '#modal-examination');
    card.setAttribute('id', examination.id);
    card.onclick = function () {
        modalToHash(examination.name.split(' ').join('-').toLocaleLowerCase());
        showAssessmentSummary(examination.id);
    };
    const bocaTestId = 1;
    if (examination.id == bocaTestId) card.style.order = -1;

    const content = document.createElement('div');
    content.classList.add('mb-3', 'mb-md-4', 'examination-box');

    const icon_container = document.createElement('div');
    const xml = examination.examination_type_contents[0].icon_svg;
    icon_container.innerHTML = xml.trim();
    const icon_svg = icon_container.getElementsByTagName('svg')[0];
    icon_svg.setAttribute('height', '2.25rem');

    const title = document.createElement('h4');
    title.textContent = examination.examination_type_contents[0].title;
    title.classList.add('examination-box-title');

    const subtitle = document.createElement('h4');
    subtitle.textContent = examination.examination_type_contents[0].subtitle;
    subtitle.classList.add('examination-box-subtitle');

    card.append(content);
    content.append(icon_container);
    content.append(title);
    content.append(subtitle);

    examination_section.append(card);
}

function showAssessmentSummary(id) {
    modalExaminationIcon.textContent = 'AAA';
    modalExaminationButtonText.innerHTML = 'LOADING...';
    modalExaminationButtonText.classList.add('loading-skeleton');
    modalExaminationTitle.classList.add('loading-skeleton');
    modalExaminationSubtitle.classList.add('loading-skeleton');
    modalExaminationSummary.classList.add('loading-skeleton');
    modalExaminationIcon.classList.add('loading-skeleton');
    modalExaminationTitle.textContent = 'Loading title...';
    modalExaminationSubtitle.textContent =
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
    modalExaminationSummary.textContent =
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
    fetch(`${process.env.API_ENDPOINT}/examination_types/${id}`, {
        method: 'GET',
        // eslint-disable-next-line no-undef
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        credentials: 'same-origin'
    })
        .then((response) => response.json())
        .then(({ examination_type }) => {
            modalExaminationIcon.innerHTML =
                examination_type.examination_type_contents[0].icon_svg.trim();
            modalExaminationTitle.textContent = examination_type.examination_type_contents[0].title;
            modalExaminationSubtitle.textContent =
                examination_type.examination_type_contents[0].subtitle;
            modalExaminationSummary.innerHTML = examination_type.examination_type_contents[0].full
                ? examination_type.examination_type_contents[0].full.trim()
                : examination_type.examination_type_contents[0].summary.trim();
            if (examination_type.is_public) {
                modalExaminationButtonText.innerHTML = 'GET STARTED';
                modalExaminationButton.href = `${process.env.EXAMINATIONS_ROOT}/guest/assessment-type/${examination_type.id}/auth`;
            } else {
                modalExaminationButton.dataset.bsTarget = '#not-available-modal';
                modalExaminationButton.dataset.bsToggle = 'modal';
                modalExaminationButtonText.innerHTML = 'REQUEST';
            }

            modalExaminationTitle.classList.remove('loading-skeleton');
            modalExaminationSubtitle.classList.remove('loading-skeleton');
            modalExaminationSummary.classList.remove('loading-skeleton');
            modalExaminationIcon.classList.remove('loading-skeleton');
            modalExaminationButtonText.classList.remove('loading-skeleton');
        });
}

function showUserSummary(id) {
    const data = user_types.find((type) => type.id === id);
    modalUserImg.setAttribute('src', data.icon);
    modalButtonTitle.textContent = data.buttonText;
    modalUserTitle.textContent = data.title;
    modalUserSubtitle.textContent = data.subtitle;
    modalUserSummary.textContent = data.summary;
    modalUserSubtitle2.textContent = data.subtitle2 || null;
    modalUserSummary2.textContent = data.summary2 || null;
    modalBottomButtonText.textContent = data.bottomButtonText || null;

    if (data.bottomButtonText) {
        modalBottomButton.classList.remove('d-none');
    } else {
        modalBottomButton.classList.add('d-none');
    }
}

fetchExaminations();

const modalToHash = (id) => {
    window.location.hash = id;
};

function revertToOriginalURL() {
    const original = window.location.href.slice(0, window.location.href.indexOf('#'));
    history.replaceState({}, document.title, original);
}

modalExamination.addEventListener('hidden.bs.modal', function () {
    revertToOriginalURL();
});
